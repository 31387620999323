/*colors*/
$accent-color: #0047ba;
$light-accent-color: #1e53a0;
$secondary-color: #93d400;
$accent-red: #ff6b00;
$gray: #e6eaed;
$light-gray: #a0a0a0;
$lighter-gray: #EFECEF;
$white: #fff;
$dark: #58595B;

/*fonts*/
$title-font: 'Montserrat';
$body-font: 'Myriad Pro';
$popings-font: 'Poppins';

/*breakpoints*/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$grid-breakpoints-down: (
    xs: 575.98px,
    sm: 767.98px,
    md: 991.98px,
    lg: 1199.98px,
    xl: 1399.98px,
    xxl: 0
);

@mixin center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin transition {
    transition: all .5s;
}