@import '../variables.scss';

@font-face {
    font-family: 'Montserrat';
    src: url(../webfonts/Montserrat-Medium.ttf);
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../webfonts/Montserrat-Bold.ttf);
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url(../webfonts/MYRIADPRO-REGULAR.OTF);
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url(../webfonts/MYRIADPRO-BOLD.OTF);
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url(../webfonts/Poppins-Medium.ttf);
    font-weight: normal;
    font-display: swap;
}

body {
    font-family: $body-font;
    font-size: 1.4rem;
    color: $dark;
}

.loader {
    @include center-flex;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    background: #fff;
    z-index: 1000;
    color: $accent-color;
}

#root:not(:empty) + .loader { 
    display: none;
}